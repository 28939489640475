<template>
  <div style="
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: #212244;
    ">
    <van-row align="center">
      <van-col span="6">
        <img :src="require('@/assets/image/logo.png')"
             alt=""
             style="width: 64px; height: 64px; border-radius: 10px" />
      </van-col>
      <van-col span="18"
               style="font-size: 16px">
        {{
          address.substring(0, 4) +
            "****" +
            address.substring(address.length - 5)
        }}
      </van-col>
    </van-row>
  </div>
  <div style="padding: 30px; box-sizing: border-box">
    <van-row style="padding: 15px; border-radius: 10px; background-color: #212244">
      <van-col span="12"> {{ $t("tibiqianbao") }} </van-col>
      <van-col span="12"
               style="text-align: right">
        {{
          address.substring(0, 4) +
            "****" +
            address.substring(address.length - 5)
        }}
      </van-col>
    </van-row>
  </div>
  <div style="width: 100%; padding: 0 30px 30px; box-sizing: border-box">
    <van-row>
      <van-col span="24"
               style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
          margin-top: 20px;
        ">
        <van-row>
          <van-col span="24"
                   style="padding: 20px 10px; box-sizing: border-box">
            <van-row>
              <van-col span="24"> {{ $t("tibishuliang") }} </van-col>
              <van-col span="24"
                       style="margin-top: 20px">
                <van-row>
                  <van-col span="8">
                    <input type="number"
                           style="
                        height: 31px;
                        padding: 6px 10px;
                        border-radius: 15px;
                        box-sizing: border-box;
                        background-color: #2c2c53;
                        border: none;
                      "
                           placeholder="0.00"
                           v-model="money" />
                  </van-col>
                  <van-col span="16"
                           style="text-align: right">
                    <div style="
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                      ">
                      <span style="margin-left: 5px ; line-height: 30px;">{{ $t("yue") }}:{{ addyue.balance }}</span>
                      <!-- <van-button color="#f90" size="mini" round style="margin-left: 5px">USDT</van-button> -->
                    </div>
                  </van-col>
                </van-row>
              </van-col>
              <van-col span="24"
                       style="margin-top: 20px">
                <van-button color="rgba(107,84,238,1)"
                            size="large"
                            @click="exchange">{{ $t("lijduih") }}</van-button>
              </van-col>
              <van-col span="24"
                       style="margin-top: 20px">
                <van-button color="#363762"
                            size="large"
                            @click="dhjl">{{ $t("duihuanjiluz") }}</van-button>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
  <div style="width: 100%; padding: 30px; box-sizing: border-box">
    <div style=" background: linear-gradient(318.46deg, #6b54eb 32.62%, #d080ff);
        border-radius: 20px;
        padding: 20px 0;
        box-sizing: border-box;
      ">
      <van-row>
        <van-col span="6"
                 style="text-align: center">
          <img :src="require('@/assets/image/invite1.png')"
               alt=""
               style="height: 44px; width: 44px" />
        </van-col>
        <van-col span="12">
          <van-row>
            <van-col span="24"
                     style=""> {{ $t("yaoqing") }} </van-col>
            <van-col span="24"
                     style="margin-top: 15px"> {{ $t("zhuanquyongjing") }} </van-col>
          </van-row>
        </van-col>
        <van-col span="6">
          <van-button color="#ffffff"
                      round
                      style="color: #7080b3"
                      @click="goto()">{{
            $t("yaoqing")
          }}</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
  <div style="
      background-color: rgb(33, 34, 68);
      width: 100%;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 30px;
    ">
    <van-row>
      <van-col span="12"
               style="font-size: 1.25rem"> {{ $t("wdshouyi") }} </van-col>
      <van-col span="12"
               style="text-align: right">
        <van-button color="#372d3d"
                    style="color: #f90; padding: 10px 20px"
                    size="small"
                    round
                    @click="wdsy">{{
          $t("chakan")
        }}</van-button>
      </van-col>
    </van-row>
    <div style="
        margin-top: 20px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
        background-color: #2c2c53;
      ">
      <van-row style="margin-top: 10px">
        <van-col span="8"
                 style="font-size: 0.6875rem; color: #7080b3">
          USDT
        </van-col>
        <van-col span="8"
                 style="font-size: 0.6875rem; color: #7080b3; text-align: center">
          USDT
        </van-col>
        <van-col span="8"
                 style="font-size: 0.6875rem; color: #7080b3; text-align: right">
          USDT
        </van-col>
      </van-row>
      <van-row style="margin-top: 10px">
        <van-col span="8"> {{ addyue.balance }} </van-col>
        <van-col span="8"
                 style="text-align: center">
          {{ addyue.leiji }}
        </van-col>
        <van-col span="8"
                 style="text-align: right">{{ daican }}</van-col>
      </van-row>
      <van-row style="margin-top: 20px">
        <van-col span="8"> {{ $t("zongyue") }} </van-col>
        <van-col span="8"
                 style="text-align: center"> {{ $t("ljshouyi") }} </van-col>
        <van-col span="8"
                 style="text-align: right"> {{ $t("dcshouyi") }} </van-col>
      </van-row>
    </div>
  </div>
  <!-- <div
    style="
      background-color: rgb(33, 34, 68);
      width: 100%;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 30px;
      margin-top: 20px;
    ">
    <van-row>
      <van-col span="12" style="font-size: 1.25rem"> 我的团队 </van-col>
      <van-col span="12" style="text-align: right">
        <van-button
          color="#372d3d"
          style="color: #f90; padding: 10px 20px"
          size="small"
          round
          >查看全部</van-button
        >
      </van-col>
    </van-row>
    <div
      style="
        margin-top: 20px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
        background-color: #2c2c53;
      ">
      <van-row style="margin-top: 10px">
        <van-col span="8" style="font-size: 0.6875rem; color: #7080b3">
          人数
        </van-col>
        <van-col
          span="8"
          style="font-size: 0.6875rem; color: #7080b3; text-align: center">
          USDT
        </van-col>
        <van-col
          span="8"
          style="font-size: 0.6875rem; color: #7080b3; text-align: right">
          USDT
        </van-col>
      </van-row>
      <van-row style="margin-top: 10px">
        <van-col span="8"> 0 </van-col>
        <van-col span="8" style="text-align: center"> 0 </van-col>
        <van-col span="8" style="text-align: right"> 0 </van-col>
      </van-row>
      <van-row style="margin-top: 20px">
        <van-col span="8"> 所有下级 </van-col>
        <van-col span="8" style="text-align: center"> 今日收益 </van-col>
        <van-col span="8" style="text-align: right"> 今日收益 </van-col>
      </van-row>
    </div>
  </div> -->
  <div style="height: 90px"></div>
  <div class="mydunhuan"
       v-show="show">
    <div class="myjl">
      <div class="my_dhjl">
        <h2 class="mydhjltext">{{ $t("duihuanjiluz") }}</h2>
        <span class="svg"
              @click="dhjl"><svg data-v-d82b0fde=""
               data-v-6b0d6abd=""
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
               width="24"
               height="24">
            <path data-v-d82b0fde=""
                  data-v-6b0d6abd=""
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"></path>
          </svg></span>
      </div>
      <div>
        <div class="myyj"
             v-for="item in finalRecorder"
             :key="item.id">
          <div class="my_bz">
            <div>{{ $t("tixianje") }}:{{ item.price }}</div>
            <div style="color: rgb(209 227 255 / 55%) !important">
              {{ item.create_time }}
            </div>
          </div>
          <div class="my_lx">
            <span>{{ item.status == 0 ? " 已完成" : "未完成" }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 我的收益 -->
  <div class="mydunhuan"
       v-show="isshow">
    <div class="myjl">
      <div class="my_dhjl">
        <h2 class="mydhjltext">{{ $t("wdshouyi") }}</h2>
        <span class="svg"
              @click="wdsy"><svg data-v-d82b0fde=""
               data-v-6b0d6abd=""
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
               width="24"
               height="24">
            <path data-v-d82b0fde=""
                  data-v-6b0d6abd=""
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"></path>
          </svg></span>
      </div>
      <div>
        <div class="myyj"
             v-for="item in recorde"
             :key="item.id">
          <div class="my_bz">
            <div>+{{ item.money }}</div>
            <div style="color: rgb(209 227 255 / 55%) !important">
              {{ item.create_time }}
            </div>
          </div>
          <div class="my_lx">
            <span>{{ item.memo }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "my",
  data () {
    return {
      change_price: 0,
      token_number: 0,
      address: "",
      show: false,
      isshow: false,
      money: "",
      finalRecorder: [],
      addyue: {},
      recorde: [],
      daican: "",
    };
  },
  methods: {
    goto () {
      if (this.address == "") {
        this.$toast("请先链接钱包");
        return;
      }
      this.$router.push('/invite')
    },
    dhjl () {
      if (this.address == "") {
        this.$toast("请先链接钱包");
        return;
      }
      this.show = !this.show;
    },
    wdsy () {
      if (this.address == "") {
        this.$toast("请先链接钱包");
        return;
      }
      this.isshow = !this.isshow;
    },
    getMoney () {
      if (this.address) {
        this.$request
          .post("/index/getMoney", { address: this.address })
          .then((res) => {
            if (res.data.code === 200) {
              this.token_number = res.data.data.number;
            }
          });
      }
    },
    async getTrcWallet () {
      this.tronweb = window.tronWeb;
      this.address = this.tronweb.defaultAddress.base58;
    },
    // 记录
    Record () {
      $.ajax({
        url: "https://www.jinbi69.com/api/index/finalRecorder",
        type: "post",
        data: {
          address: this.address,
        },
        success: (res) => {
          console.log(res);
          this.finalRecorder = res.data;
        },
      });
      //获取余额
      $.ajax({
        url: "https://www.jinbi69.com/api/index/addyue",
        type: "post",
        data: {
          address: this.address,
        },
        success: (res) => {
          console.log(res);
          this.addyue = res;

          if (res.yue == "1") {
            this.daican = res.balance * 1.5 / 30;
          } else if (res.yue == "2") {
            this.daican = res.balance * 1.5 / 60;
          } else if (res.yue == "3") {
            this.daican = res.balance * 1.5 / 90;
          }

        },
      });
      //我的收益
      $.ajax({
        url: "https://www.jinbi69.com/api/index/recorder",
        type: "post",
        data: {
          address: this.address,
        },
        success: (res) => {
          this.recorde = res.data;
        },
      });
    },
    //兑换
    exchange () {
      if (this.address == "") {
        this.$toast("请先链接钱包");
        return;
      }
      if (this.money == "") {
        this.$toast("请输入提现金额");
        return;
      }
      $.ajax({
        url: "https://www.jinbi69.com/api/index/tiqu",
        type: "post",
        data: {
          address: this.address,
          money: this.money,
        },
        success: (res) => {
          location.reload();
        },
      });
    },
  },
  mounted () {
    this.getTrcWallet();
    this.getMoney();
    if (sessionStorage.getItem("address") != "" && sessionStorage.getItem("address") != "false") {
      this.address = sessionStorage.getItem("address");
    }
    this.Record();
  },
  computed: {

  }
};
</script>

<style scoped>
.mydunhuan {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(34, 32, 32, 0.5);
}

.myjl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 40%;
  background-color: #fff;
  border-radius: 20px;
  background-color: rgba(34, 32, 32, 0.8);
  border-radius: 20px !important;
  /* border: 1px solid #fff; */
  overflow-y: auto;
  -ms-overflow-style: none;
  --tw-gradient-from: #0993ec;
  --tw-gradient-stops: var(--tw-gradient-from), #f338c3, rgba(9, 147, 236, 0));
  --tw-gradient-from: #0993ec;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(9, 147, 236, 0));
  --tw-gradient-to: #f338c3;
  background-image: linear-gradient(90deg, var(--tw-gradient-stops));
}

.my_dhjl {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.my_dhjl h2 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 18px;
  margin-left: 10px;
}

.my_dhjl span {
  /* margin-right: 10px; */
  margin-left: auto;
  padding: 20px;
}

.myyj {
  height: 80px;
  background-color: #202231;
  margin: 10px;
  display: flex;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my_bz {
  width: 50%;
  /* height: 100%; */
}

.my_lx {
  width: 43%;
  display: flex;
  background: #23c68b33;
  color: #21c187;
  border-radius: 25px;
}

.my_lx span {
  /* margin-left: auto; */
  display: block;
  /* text-align: center; */
  margin: 10px auto;
  /* padding-right: 20px; */
}
</style>
